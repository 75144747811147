<template>
  <div class="pending-approval">
    <v-container fluid>
      <div class="icon">
        <img src="@/assets/images/wall-clock.png" width="100" alt="">
      </div>

      <div class="welcome">
        <h1>Your Account Is Pending Approval</h1>
      </div>

      <p class="description">
        We will send you an email when your account has been approved.
      </p>

    </v-container>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
    };
  },
  computed: {
    ...mapGetters("auth", {
      user: "user",
    }),
  },
  created() {
    if (this.user && this.user.approved == true) {
        this.$router.push('/dashboard');
    }
  },
  methods: {},
};
</script>

<style scoped lang="scss">
.loading {
  text-align: center;
  padding-top: 20px;
  margin-top: 30px;
}

.pending-approval {
  padding: 20px;

  .icon {
    text-align: center;
    margin-top: 40px;
    margin-bottom: 40px;
  }

  .welcome {
    text-align: center;
  }

  .description {
    text-align: center;
    margin-top: 20px;
  }
}
</style>